@import '~/stylesheets/variables/spacing.scss';

.tt-Line {
    position: relative;
    background-color: $color-background;
    display: block;

    &-horizontal {
        height: 1px;
        width: 100%;
    }

    &-vertical {
        height: 100%;
        width: 1px;
    }
}
