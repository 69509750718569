.tt-FieldCollection {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/breakpoints.scss';

    width: 100%;

    .tt-FieldGroup {
        padding: getSpacing(1) 0;
        border-top: 1px solid transparent;

        &::before {
            content: '';
            position: relative;
            display: block;
            height: 1px;
            background-color: $color-border;
            top: -1 * getSpacing(1);
        }

        &:first-child {
            padding-top: getSpacing(2);

            &::before {
                display: none;
            }
        }

        &:last-child {
            padding-top: getSpacing(2);
        }

        &--is-form {
            padding: getSpacing(2) 0;

            &::before {
                top: -1 * getSpacing(2);
            }

            &:first-child {
                padding-top: getSpacing(3);
            }
        }

        &--is-inset-group.tt-FieldGroup {
            border: 0;
            padding: getSpacing(2);
            padding-left: getSpacing(2);
            padding-right: getSpacing(2);

            &::before {
                display: none;
            }

            &:first-child {
                padding-top: getSpacing(2);
            }
        }

        &--is-highlighted-group {
            background: #f2f4f5;
            border-top: 1px solid $color-border;

            &::before {
                display: none;
            }
        }
    }

    &--cover {
        .tt-FieldGroup {
            border: 0;

            .tt-Field__title {
                padding-bottom: getSpacing(1) - 4;
                white-space: nowrap;
            }

            .tt-Field__content {
                width: 100%;
            }

            &__list {
                > .tt-ListItem {
                    margin-bottom: 0;

                    .tt-ListItem:first-of-type {
                        margin-bottom: getSpacing(3) - 6px;
                    }
                }

                > .tt-ListItem:last-of-type {
                    margin-top: getSpacing(3);
                }
            }

            &--is-inset-group.tt-FieldGroup {
                padding: getSpacing(2);
                padding-left: getSpacing(2);
                padding-right: getSpacing(2);
            }

            &--is-form {
                padding-top: getSpacing(3);

                &::before {
                    top: -1 * getSpacing(3);
                }
            }
        }
    }

    &--modal {
        padding: 0;

        .tt-FieldGroup,
        .tt-FieldCollectionFooter {
            padding-left: getSpacing(1.5);
            padding-right: getSpacing(1.5);
        }

        .tt-FieldGroup {
            &:first-child {
                padding-top: getSpacing(1);
            }

            &--is-form {
                &:first-child {
                    padding-top: getSpacing(2);
                }
            }

            &--is-inset-group.tt-FieldGroup--is-form {
                padding-left: getSpacing(3);
                padding-right: getSpacing(3);
            }
        }

        .tt-FieldCollectionFooter {
            padding-top: getSpacing(2);
            padding-bottom: getSpacing(2);
            border-top: 1px solid $color-border;
        }
    }

    &-wrap {
        .tt-FieldGroup {
            border: 0;
        }
    }

    &--password-form .tt-FieldGroup--is-form {
        .tt-Field {
            margin-bottom: 18px;
        }

        .tt-Field + .tt-Field {
            padding-top: 18px;
        }

        .tt-Field:nth-child(2) .tt-Field__content {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                display: block;
                top: -19px;
                left: 0;
                right: 0;
                height: 1px;
                max-width: 492px;
                background-color: #e1e3e6;
            }
        }

        .tt-Field:last-child {
            padding-top: 12px;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding: 0;

        .tt-FieldGroup {
            padding: getSpacing(2) 0;

            &::before {
                top: -1 * getSpacing(2);
            }

            &:first-child {
                padding-top: getSpacing(3);
            }

            &--is-form {
                padding: getSpacing(3) 0;

                &::before {
                    top: -1 * getSpacing(3);
                }

                &:first-child {
                    padding-top: getSpacing(4);
                }
            }

            &--is-inset-group.tt-FieldGroup {
                padding: getSpacing(2);
                padding-left: getSpacing(2);
                padding-right: getSpacing(2);

                &:first-child {
                    padding-top: getSpacing(2);
                }
            }

            .tt-Field + .tt-FieldGroup--is-compact {
                margin-top: getSpacing(1);
            }

            &--is-compact.tt-FieldGroup {
                padding: getSpacing(1);
                padding-left: getSpacing(1.5);
                padding-right: getSpacing(1.5);
            }
        }

        &--modal {
            .tt-FieldGroup,
            .tt-FieldCollectionFooter {
                padding-left: getSpacing(3);
                padding-right: getSpacing(3);
            }

            .tt-FieldGroup {
                &:first-child {
                    padding-top: getSpacing(2);
                }

                &--is-form {
                    &:first-child {
                        padding-top: getSpacing(3);
                    }
                }

                &--is-inset-group.tt-FieldGroup--is-form {
                    padding-left: getSpacing(3);
                    padding-right: getSpacing(3);
                }

                &--is-inset-group.tt-FieldGroup--no-next-field {
                    margin-bottom: 0;
                }
            }
        }
    }
}
