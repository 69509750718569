.tt-FieldWithButton {
    display: flex;
    flex-direction: row;

    &__field {
        width: 100%;
    }

    &__button {
        margin-left: 16px;
    }
}
