$color-red: #e7555a;
$color-orange: #f89406;
$color-green-old: #2bc572;
$color-green: #009060;
$color-white: #ffffff;
$color-blue-dark: #122947;
$color-blue-light: #64caf0;
$color-grey: #cccccc;
$color-grey-darker: #92979b;
$color-black: #000000;
$color-greyish-blue: #324058;
$color-grey-light: #c4c9cd;
$color-grey-subtle: #a8aeb3;
$color-grey-background: #f3f4f5;
$color-light-perrywinkle: #e1e3e6;
$color-grey-dark: #61666e;

$color-primary: #3f64a4;
$color-primary-active: rgba(255, 255, 255, 0.1);

$color-cover-background: $color-white;

$color-text: #2d3740;
$color-text-subtle: #909599;
$color-text-warning: $color-red;
$color-text-success: $color-green;
$color-text-title: $color-blue-dark;
$color-text-button: #2d3e4b;
$color-text-button-text: #3f64a4;
$color-text-button-secondary-submit: #239c5b;

$color-nav-main: $color-blue-light;
$color-nav-sub: $color-green;

$color-input-disabled: #f3f4f5;
$color-input-placeholder: #b2b2b2;
$color-input-border-error: #e95156;

$color-link: #0437b0;
$color-link-hover: #0437b0;
$color-link-subtle: #5e758f;
$color-link-subtle-hover: $color-link-subtle;
$color-link-danger: #e85156;
$color-link-danger-hover: #b53f43;

$color-border: #e1e4e6;
$color-border-blue: $color-blue-light;
$color-border-input: #c4c9cc;
$color-border-input-hover: $color-grey-subtle;
$color-border-input-focus: #2490e7;

$color-button: #ffffff;
$color-button-edit: #122947;
$color-button-submit: $color-green;
$color-button-danger: #df4d52;

$color-button-hover: #f2f4f5;
$color-button-edit-hover: #1a3b66;
$color-button-submit-hover: #00ad74;
$color-button-danger-hover: #d1494e;

$color-button-text: #2d3740;
$color-button-text-inverted: #ffffff;
$color-button-text-danger: #e85156;
$color-button-text-error: #e95156;

$color-button-border: #949da4;
$color-button-border-edit: transparent;
$color-button-border-submit: transparent;
$color-button-border-secondary-submit: $color-green;
$color-button-border-danger: #e85156;
$color-button-border-error: #e95156;

$color-border-button: $color-grey-subtle;

$color-background: #f2f4f5;
$color-background-table: #f8f9fa;

$color-tag: #2d92e3;
$color-tag-border: #1c73b8;

$color-label: $color-grey-background;
$color-label-inverse: #ebeced;
$color-label-error-background: #fadcdd;
$backgroundcolor-label-inverse: #989ea4;

$color-filter-background: #f8f9fa;

$color-toast-success: rgba(#abe8c7, 0.9);
$color-toast-success-text: #209155;
$color-toast-info: rgba(#b0d7f6, 0.9);
$color-toast-info-text: #1d6fb3;
$color-toast-danger: rgba(#f6b9bb, 0.9);
$color-toast-danger-text: #cf484d;
$color-toast-warning: rgba(#fdedc4, 0.9);
$color-toast-warning-text: #c48954;

$color-tooltip-background: rgba(#2d3740, 0.9);

$border-pdf-page-break: 2px dashed #ccc;

$color-note-background: #fae9a2;
$color-note-border: #e4ca59;
$color-note-meta-text: #857a42;
$color-note-meta-text-separator: #b8a85c;

$color-square-counter-icon: #e85156;

$color-text-danger: #e85156;

// Password
$very-weak-password-strenght-color: #e85156;
$weak-password-strength-color: #e87b51;
$acceptable-password-strength-color: #e8af51;
$good-password-strength-color: #2cc472;
$strong-password-strength-color: #2490e6;

$color-text-variable-button-background: #f2f4f5;
$color-text-variable-button-background-active: #e5ebf7;
