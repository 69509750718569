.tt-StatisticWrap {
    @import '~/stylesheets/variables/spacing.scss';

    padding: 0;

    &__title {
        display: inline-block;
        font-weight: normal;
        font-size: 16px;
    }

    &__bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: getSpacing(1.5);
    }

    &__action {
        margin-left: getSpacing(1);
    }

    &:first-of-type {
        margin-top: 0;
    }
}
