.tt-ValueActionField {
    @import '~/stylesheets/variables/colors.scss';

    display: flex;
    flex-direction: column;
    width: 100%;

    .tt-ValueActionFieldItem ~ .tt-ValueActionFieldItem {
        border-top: 1px solid $color-light-perrywinkle;
        padding-top: 4px;
    }
}
