.tt-VariableExamFields {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    &__controls {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }

    &__delete-button.tt-Button {
        margin-left: getSpacing(1);
        height: 34px;
        padding: 6px 8px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -6px;
            top: 50%;
            transform: translateY(-50%) rotateZ(45deg);
            border: 1px solid $color-button-border;
            border-top: 0;
            border-right: 0;
            height: 10px;
            width: 10px;
            background-color: #fff;
        }

        &:hover::before {
            background-color: #f3f4f5;
        }
    }

    &__add-field-button {
        margin-top: 4px;
        margin-bottom: getSpacing(0.5);
    }

    &__field-container {
        width: 100%;

        & + .tt-VariableExamFields__field-container {
            margin-top: getSpacing(1);
        }

        & + .tt-VariableExamFields__add-field-button {
            margin-top: getSpacing(2);
        }
    }

    .tt-TagPicker {
        width: 100%;
    }
}
