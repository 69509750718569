.tt-TagPicker {
    @import '~/stylesheets/mixins/input.scss';
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    text-align: left;

    & &__control {
        max-width: $input-max-width;
        padding: 1px 2px;
        border-color: $color-border-input;
        border-style: solid;
        border-width: 1px;
        border-radius: 2px;
        font-size: 14px;
        font-family: $font-primary;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        background-color: $color-white;
        transition: padding;
        transition-duration: 200ms;
        min-height: 41px;

        &:hover {
            border-color: $color-border-input-hover;
        }

        &--is-focused {
            border-color: $color-border-input-focus;
            box-shadow: 0 0 3px 0 rgba(36, 144, 230, 0.3), inset 0 2px 1px 0 rgba(0, 0, 0, 0.08);
        }

        &--menu-is-open {
            border-color: $color-border-input;
            border-radius: 2px 2px 0 0;
            border-bottom-color: $color-light-perrywinkle;

            &:hover {
                border-bottom-color: $color-border;

                & + .tt-TagPicker__menu {
                    border-color: $color-border-input-hover;
                    border-top-color: $color-border;
                }
            }
        }
    }

    & &__menu {
        margin: 0;
        max-width: $input-max-width;
        border-radius: 0 0 2px 2px;
        box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);
        border-color: $color-grey;
        border-width: 1px;
        border-style: solid;
        border-top: none;
        color: $color-text;

        &-list {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    & &__input {
        input {
            font-family: inherit;
        }
    }

    & &__indicator-separator {
        display: none;
    }

    & &__option {
        background-color: $color-white;
        cursor: pointer;

        &--is-selected {
            color: $color-text;
            background-color: #f7f9fa;
        }

        &--is-focused {
            background-color: $color-border;
        }
    }

    & &__placeholder {
        color: $color-input-placeholder;
    }

    & &__multi-value {
        color: white;
        margin: 2px;

        &__label,
        &__remove {
            background: $color-tag;
            border: 1px solid $color-tag-border;
            color: inherit;
            border-radius: 3px;
        }

        &__label {
            border-right: 0;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &__remove {
            border-left: 0;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            cursor: pointer;

            &:hover {
                background-color: $color-tag-border;
                color: inherit;
            }
        }
    }

    & &__clear-indicator {
        display: inline-block;

        .tt-Icon {
            color: hsl(0, 0%, 80%);
            font-size: 12px;
            margin-top: 3px;

            &:hover {
                color: hsl(0, 0%, 60%);
            }
        }
    }

    &--is-multi {
        .tt-TagPicker__control {
            padding: 0;
        }
    }

    &--is-creatable {
        .tt-TagPicker {
            &__create-row {
                align-items: baseline;
                color: $color-link;

                .tt-Icon {
                    padding-right: 10px;
                    color: inherit;

                    svg {
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            &__option {
                & > .tt-TagPicker__create-row {
                    padding: 0;
                }

                &--is-focused {
                    background-color: $color-border !important; // override !important used by react-select

                    // &:hover {
                    //     background-color: $color-border;
                    // }

                    // .tt-TagPicker__create-row:hover {
                    // }
                }
            }
        }
    }

    &--has-value {
        color: $color-text;
    }

    &--is-disabled {
        .tt-TagPicker__control {
            @include input-disabled();

            .tt-TagPicker__single-value {
                color: inherit;
            }
        }
    }
}

.tt-Field--has-error {
    @import '~/stylesheets/mixins/input.scss';

    .tt-TagPicker {
        @include input-error();
    }
}
