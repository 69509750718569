@import '~/stylesheets/variables/colors.scss';
@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/variables/fonts.scss';

$input-max-width: 480px;

@mixin input() {
    width: 100%;
    max-width: $input-max-width;
    padding: getSpacing(1);
    border: 1px solid $color-border-input;
    border-radius: 2px;
    font-size: 14px;
    font-family: $font-primary;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    background-color: $color-white;
    transition: padding;
    transition-duration: 200ms;

    @include placeholder {
        color: $color-input-placeholder;
    }

    &:hover {
        border-color: $color-border-input-hover;
    }

    &:focus {
        border-color: $color-border-input-focus;
        box-shadow: 0 0 3px 0 rgba(36, 144, 230, 0.3), inset 0 2px 1px 0 rgba(0, 0, 0, 0.08);
    }

    &[type='date'] {
        padding-right: 0;
    }
}

@mixin input-disabled() {
    background-color: $color-input-disabled;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;

    &:hover {
        border-color: $color-border-input;
    }
}

@mixin input-error() {
    border-color: $color-input-border-error !important;

    &:hover {
        border-color: darken($color-input-border-error, 10%) !important;
    }

    &:focus {
        border-color: darken($color-input-border-error, 10%) !important;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}
