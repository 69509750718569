.tt-Crumb {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/breakpoints.scss';
    @import '~/stylesheets/variables/spacing.scss';

    font-size: 12px;
    font-weight: 400;
    color: $color-text-subtle;

    .tt-Link {
        text-decoration: none;
        font-size: 12px;
        color: $color-text-subtle;

        &__content {
            font-weight: 400;
        }
    }

    .tt-ToolTip {
        margin-left: getSpacing(1);
    }

    .tt-Spinner {
        display: inline-block;
        margin-top: 0;
    }
}
