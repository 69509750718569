@mixin yearTotalShadow() {
    position: relative;
    overflow: visible;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: -1px; // overlap bottom border
        right: 100%;
        width: 12px;
        background: linear-gradient(to right, rgba(black, 0) 0%, rgba(black, 0.098) 100%);
        z-index: 1;
    }
}
