.tt-SubNav {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/breakpoints.scss';
    @import '~/stylesheets/animations/showHideNav.scss';

    width: 100%;
    background-color: $color-nav-sub;
    padding: 0px getSpacing(1) 0px getSpacing(1.5);
    top: 48px;
    z-index: 5;
    position: fixed;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    transition: all 0.5s ease-in-out;
    // hide scrollbar
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    transition: $menu-transition;

    &::before {
        content: '';
        position: fixed;
        right: 0;
        width: 48px;
        height: 32px;
        background-image: linear-gradient(to right, rgba($color-green, 0), rgba($color-green, 0.8));
        pointer-events: none;
        z-index: 1;
    }

    &__placeholder {
        display: block;
    }

    .tt-SubNavLink {
        margin-right: 24px;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding-left: 40px;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        &::before {
            background-image: none;
        }
    }
}
