.tt-List {
    &--horizontal {
        text-align: right;

        > .tt-ListItem {
            display: inline-block;
            float: left;
            text-align: left;

            &--right {
                float: none;
            }
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    &--for-buttons {
        margin: -1 * getSpacing(0.5);

        > .tt-ListItem {
            padding: getSpacing(0.5);
        }
    }

    &--for-small-buttons {
        margin: -3px;

        > .tt-ListItem {
            padding: 3px;
        }
    }

    &--stretch {
        display: flex;
        align-items: center;

        > .tt-ListItem {
            flex: 1;
        }
    }

    &--has-extra-spacing {
        > .tt-ListItem + .tt-ListItem {
            margin-left: getSpacing(1);
        }
    }
}
