.tt-EdisaImportTable {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    td {
        &:nth-child(1) {
            color: $color-text-subtle;
        }

        &:nth-child(2) {
            color: $color-text;
            max-width: 300px;
        }
    }

    .tt-EdisaImportTableRow {
        &--has-failed {
            .tt-TableCell {
                color: $color-text-warning;

                &:last-child {
                    .tt-Icon {
                        vertical-align: middle;
                        margin-right: getSpacing(0.5);
                    }
                }
            }
        }

        &--has-reset-intake {
            .tt-TableCell {
                &:last-child {
                    color: $color-text-success;

                    .tt-Icon {
                        vertical-align: middle;
                        margin-top: 2px;
                        margin-right: getSpacing(0.5);
                    }
                }
            }
        }
    }
}
