.tt-Field {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/breakpoints.scss';

    width: 100%;
    font-size: 0;
    transition: opacity, transform;
    transition-duration: 200ms;

    .tt-ValidationError {
        margin-top: getSpacing(1);
    }

    .tt-Hint {
        margin-top: getSpacing(0.5);
    }

    &__wrapper {
        width: 100%;
    }

    &__title,
    &__content {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
    }

    &__title {
        padding: getSpacing(0.5) 0;
        color: $color-grey-darker;
        padding-right: getSpacing(2);
    }

    &__content {
        padding: getSpacing(0.5) 0;

        & > .tt-CheckBox {
            margin-top: 10px;
        }

        & > * + * {
            margin-top: getSpacing(0.5);
        }

        .tt-Paragraph {
            margin-bottom: getSpacing(1);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__error {
        display: none;
    }

    &__hint {
        display: block;
        clear: both;
        color: $color-text-subtle;
        padding-left: getSpacing(0.5);
        font-size: 12px;
        line-height: 1.6em;
        padding-top: 4px;
    }

    &--is-removable {
        & .tt-Field__wrapper {
            display: flex !important;
            flex-direction: row;
            align-items: center;
        }

        .tt-Field__content {
            margin-left: calc(35px / 2);
        }

        .tt-Field__remove-button {
            margin-left: 6px;
            padding: 0 7px;
            height: 32px;
        }
    }

    .tt-FieldText {
        padding-top: 10px;
    }

    &--light {
        .tt-Field__title {
            color: $color-white;
            font-weight: normal;
        }

        color: $color-white;
    }

    &--no-title {
        .tt-Field__content {
            width: 100%;
        }
    }

    &--has-error {
        .tt-Field__title,
        .tt-Field__content {
            color: $color-red;
        }

        .tt-Field__error {
            display: block;
            clear: both;
            color: $color-red;
            padding-left: getSpacing(0.5);
            font-size: 12px;
            line-height: 1.6em;
        }
    }

    &--is-disabled {
        pointer-events: none;

        .tt-Field__content {
            opacity: 0.5;
        }
    }

    &--is-loading {
        .tt-Spinner {
            margin-top: 10px;
        }
    }

    &--inline-edit {
        .tt-Button {
            margin-left: getSpacing(0.5);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &--is-bold {
        .tt-Field__title {
            font-weight: 600;
        }
    }

    &--is-dark {
        .tt-Field__title {
            color: $color-blue-dark;
        }
    }

    &--has-grey-background {
        .tt-Field__content {
            padding: getSpacing(1);
        }
        .tt-Field__image {
            padding: getSpacing(3);
        }
        .tt-HeaderTitle {
            font-size: 16px;
            color: $color-black;
        }
    }

    .tt-FieldCollection--password-form .tt-FieldGroup--is-form &--has-password-hint {
        margin-bottom: -10px !important;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__title {
            width: 35%;
        }

        &__content {
            width: 65%;
        }

        &--is-compact {
            .tt-Field__title {
                width: auto;
            }

            .tt-Field__content {
                width: auto;
            }
        }

        &--is-indented {
            .tt-Field__title {
                padding-left: getSpacing(1);
            }
        }
    }
}
