* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:focus:not(.focus-ring) {
    outline: none;
}

.tt-TextArea:focus,
.tt-Input:focus {
    outline: none;
}
