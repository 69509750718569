.tt-ProcessItem {

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    &__warning {
        font-size: 14px;
        max-width: initial;
    }

    &__extension {
        .tt-TableWrap {
            padding-top: 18px;
        }
    }

    &__name .tt-Link,
    &__name span {
        font-weight: 600;
        font-size: 16px;
    }

    &__table-wrap {
        margin-top: 10px;
    }

    &__table {
        .tt-SimpleTableCell {
            width: 300px;

            &--is-bold {
                width: 200px;
            }
        }
    }
}

.tt-small-ListItem {
    display: block;
    font-size: 16px;
}
