.tt-Label {
    @import '~/stylesheets/variables/colors.scss';

    display: inline-block;
    padding: 0 6px;
    background-color: $color-label;
    color: $color-text-subtle;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;

    &--has-inverse-colors {
        color: $color-button-text-inverted;
        background-color: $backgroundcolor-label-inverse;
    }
}
