@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/variables/breakpoints.scss';

.tt-TimelineWrap {
    padding: getSpacing(1) getSpacing(1.5) 0;
    max-width: $breakpoint-largedesktop;
    margin-left: auto;
    margin-right: auto;
    font-size: 0;

    .tt-TimelineInfo {
        display: none;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding: getSpacing(3) getSpacing(3) 0;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        $timeline-width: 406px;

        .tt-Timeline,
        .tt-TimelineInfo {
            display: inline-block;
            vertical-align: top;
        }

        .tt-TimelineInfo {
            margin-top: -7px;
            max-width: $timeline-width - getSpacing(3);
        }

        .tt-Timeline {
            margin-right: getSpacing(3);
            width: calc(100% - #{$timeline-width});
        }
    }
}
