.tt-ExamDownloader {
    @import '~/stylesheets/variables/spacing.scss';

    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .tt-Icon {
        max-height: 16px;
        margin-left: 8px;
        cursor: pointer;
    }
}
