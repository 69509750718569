.tt-TimelineMessage {
    @import '~/stylesheets/variables/spacing.scss';

    font-size: 0;

    &__icon,
    &__content {
        display: inline-block;
        font-size: 14px;
        vertical-align: top;
    }

    &__icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid black;
        text-align: center;
        line-height: 36px;
        margin-right: getSpacing(1);
    }

    &:hover &__expandable-button {
        opacity: 1;
    }

    &__content {
        border: 1px solid #c4c9cc;
        width: calc(100% - #{36px + getSpacing(1)});
        border-radius: 2px;
        position: relative;

        &__body {
            position: relative;
            padding: getSpacing(1);
            padding-left: getSpacing(1.5);
            padding-right: getSpacing(2);
            overflow: hidden;

            img {
                max-width: 100%;
            }

            &--expandable {
                max-height: 225px;
                cursor: pointer;

                .tt-Icon {
                    position: absolute;
                    top: getSpacing(1);
                    right: 18px;
                    cursor: pointer;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 60px;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                    bottom: 0;
                }
            }

            &--expanded {
                cursor: default;
                max-height: none;
                &:after {
                    display: none;
                }
            }
        }

        &__footer {
            font-size: 12px;
            border-top: 1px solid #c4c9cc;
            background-color: #f8f9fa;
            color: $color-text-subtle;
            padding: getSpacing(0.5);
            padding-left: getSpacing(1.5);
            padding-right: getSpacing(1.5);
            text-align: left;

            .tt-ListItem {
                display: block;
                float: none;
            }

            &__targets {
                vertical-align: top;
                white-space: nowrap;

                .tt-Icon {
                    vertical-align: middle;
                    margin-right: getSpacing(0.5);
                }
            }

            &__buttons {
                white-space: nowrap;
                overflow: hidden;
                margin-bottom: -5px;
                opacity: 0;
                transform: translateY(-10px);
                transition-property: opacity, transform;
                transition-duration: 150ms;

                .tt-Link {
                    font-size: 12px;
                }
                .tt-Link + .tt-Link {
                    margin-left: 8px;
                }
            }

            &__separator {
                vertical-align: top;
                display: inline-block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #c4c9cc;
                margin-left: getSpacing(1);
                margin-right: getSpacing(1);
                margin-top: 9px;
            }
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            display: block;
            bottom: -1px;
        }
        &:after {
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;

            border-right: 5px solid #fff;
            top: 11px;
            left: -5px;
        }
        &:before {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;

            border-right: 7px solid #c4c9cc;
            top: 9px;
            left: -7px;
        }
    }

    &:hover {
        .tt-TimelineMessage__content__footer__buttons {
            opacity: 1;
            transform: none;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-TimelineMessage__content__footer {
            text-align: right;

            .tt-ListItem {
                display: inline-block;
                float: left;

                &--right {
                    float: none;
                }
            }
        }
    }
}
