.tt-AttendanceDot {
    display: inline-block;

    &__overlay {
        max-width: 30ch;
    }

    &__reason,
    &__note {
        text-align: center;
    }

    &--placeholder {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #e1e3e6;
    }
}
