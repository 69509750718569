.tt-RevenueTable {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    .tt-TableCell {
        color: $color-text;
        padding-left: getSpacing(1);
        padding-right: getSpacing(1);

        &:first-child,
        &:last-child,
        &:nth-child(2) {
            padding-left: getSpacing(1.5);
        }

        &:first-child,
        &:last-child,
        &:nth-last-child(2) {
            padding-right: getSpacing(1.5);
        }
    }
}
