.tt-Table {
    width: 100%;
    min-width: 720px;
    table-layout: fixed;
    -webkit-text-size-adjust: none;

    col {
        width: 25px;

        &:hover {
            background-color: #f0f;
        }
    }

    &--has-auto-layout {
        table-layout: auto;

        .tt-TableCell {
            max-width: none;
        }
    }
}
