@import '~/stylesheets/variables/spacing.scss';

.tt-VariableMultiInput {
    > .tt-MultiInput__item {
        width: 100%;

        &:last-child {
            width: auto;
        }
    }

    &__item {
        width: 100%;
        text-align: left;
        $button-width: 42px;

        &__content,
        &__remove {
            display: inline-block;
            vertical-align: top;
        }

        &__content {
            width: 100%;
            > * + * {
                margin-top: getSpacing(0.5);
            }
        }
        &__remove {
            width: $button-width;
            padding: getSpacing(1) !important;
        }

        &--has-button {
            .tt-VariableMultiInput__item__content {
                width: calc(100% - #{getSpacing(0.5) + $button-width});
                margin-right: getSpacing(0.5);
            }
        }
    }

    &__add {
    }
}
