.tt-ContractTableCell {
    display: flex;

    &__pdf-link {
        margin-left: 12px;

        .tt-Link__content:only-child {
            font-weight: normal;
        }
    }

    &__date-range {
        &.tt-Subtle {
            margin-left: auto;

            > span {
                margin-right: 6px;

                &:last-of-type {
                    margin-right: 0;
                    margin-left: 6px;
                }
            }
        }
    }

    &__signature-button {
        margin-left: auto;
        margin-top: -6px;

        .tt-Button {
            margin-top: 2px;
        }
    }
}
