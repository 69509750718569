.tt-InvoiceRowExpansionContent {
    @import '../variables/spacing.scss';

    .tt-ButtonGroup {
        + .tt-ButtonGroup {
            margin-left: getSpacing(1);
        }
    }

    &__side-button-group {
        float: right;
    }

    &__reupload-button.tt-Button {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        height: auto;
        vertical-align: middle;
        margin-left: 10px;
    }
}
