@mixin disabled($c, $am) {
    cursor: not-allowed;
    color: lighten($c, $am);

    &:hover {
        color: lighten($c, $am);

        .tt-Link__content {
            text-decoration: none;
        }
    }
}

.tt-Link {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/fonts.scss';

    border: 0;
    box-shadow: none;
    color: $color-link;
    padding: 0;
    font-weight: normal;
    font-family: $font-primary;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;

    &__left-icon,
    &__right-icon {
        display: inline-block;
        vertical-align: middle;

        .tt-Icon {
            margin: 0 -8px;
            margin-left: 0px;
        }
    }

    &__left-icon + &__content {
        margin-left: 8px;
    }

    &__content + &__right-icon {
        margin-left: 8px;
    }

    &:hover {
        color: $color-link-hover;

        .tt-Link__content {
            text-decoration: underline;
        }
    }

    &--emphasized {
        font-weight: 600;
    }

    &--is-disabled {
        @include disabled($color-link-subtle, 10%);
    }

    &--is-loading {
        .tt-Spinner {
            display: inline-block;
            margin-left: getSpacing(0.5);
        }
    }

    &--danger {
        color: $color-link-danger;

        &:hover {
            color: $color-link-danger-hover;
        }

        &.tt-Link--is-disabled {
            @include disabled($color-link-danger, 10%);
        }
    }

    &--subtle {
        color: $color-link-subtle;

        &:hover {
            color: $color-link-subtle-hover;
        }

        &.tt-Link--is-disabled {
            @include disabled($color-link-subtle, 10%);
        }
    }

    &--has-line-through {
        .tt-Link__content {
            text-decoration: line-through;
        }
    }

    &--small {
        font-size: 12px;
    }
}
