.tt-ExamsAbilityFieldGroup {
    @import '~/stylesheets/variables/spacing.scss';

    &__document-list {
        margin-bottom: getSpacing(2);
    }

    .tt-FieldGroup--is-inset-group {
        margin-top: getSpacing(1);
    }
}
