.tt-CandidateProgressStatusCell {
    @import '~/stylesheets/variables/colors.scss';

    .tt-BrickTableCell__content .tt-Icon {
        position: relative;
        font-weight: bold;
        font-size: 14px;

        display: inline-block; // in favor of hover-area for tooltip
        padding: 10px; // in favor of hover-area for tooltip

        &--minus {
            top: 2px;
        }
    }

    &--for-exempt .tt-BrickTableCell__content {
        background-color: #d3e9f9; // todo: check with design
        color: #2690e2; // todo: check with design
    }

    &--for-no-show .tt-BrickTableCell__content {
        background-color: $color-light-perrywinkle; // todo: check with design
        color: $color-grey-subtle; // todo: check with design
    }
}
