.tt-TimelineMessageInput {
    position: relative;
    border: 1px solid $color-border-input;
    border-radius: 2px;

    $buttons-border-bottom: 1px solid $color-border-input;

    &__buttons__wrapper {
        position: relative;
        z-index: 1;
        border-bottom: $buttons-border-bottom;
        background-color: $color-filter-background;

        // Scroll fade
        &::before {
            content: '';
            position: absolute;
            right: 0;
            width: getSpacing(2);
            top: 0;
            height: 40px;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $color-background);
            pointer-events: none;
            z-index: 2;
        }
    }

    &__buttons__wrapper-fix {
        margin-bottom: -1px;
    }

    &__buttons__scroller {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        // hide scrollbar
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__buttons {
        position: relative;
        z-index: 1;
        padding: 0 8px;
        white-space: nowrap;
        text-align: left;

        > .tt-ListItem {
            float: none;
        }

        &__button.tt-Button {
            border: 0;
            border-radius: 0;
            font-size: 12px;
            background-color: transparent;
            color: $color-text-subtle;
            padding: 0 14px;
            border-bottom: $buttons-border-bottom;

            .tt-Button__content {
                margin-left: getSpacing(1.5) - 2px;
            }

            .tt-Icon {
                filter: grayscale(100%) contrast(0%) opacity(80%);
            }

            &:active {
                box-shadow: none;
            }

            &.tt-TimelineMessageInput__buttons__button--is-active {
                color: #2d3740;

                .tt-Icon {
                    filter: none;
                }

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    bottom: -1px;
                }
                &:after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid $color-white;
                    left: 16px;
                }
                &:before {
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid $color-border-input;
                    left: 14px;
                }
            }

            &:hover {
                background-color: transparent;
                color: #2d3740;

                .tt-Icon {
                    filter: none;
                }
            }
        }
    }

    &__textarea,
    &__textarea.tt-TextArea {
        width: 100%;
        max-width: 100%;
        display: block;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        min-height: 120px;

        &:focus {
            box-shadow: none;
        }
    }
}

.tt-Field--has-error {
    .tt-TimelineMessageInput {
        @include input-error();
    }
}
