@import '~/stylesheets/mixins/input.scss';
@import '~/stylesheets/variables/colors.scss';

.tt-Select {
    @include input();

    -webkit-appearance: none;
    -webkit-border-radius: 2px;
    background: url("data:image/svg+xml;utf8,<svg width='10px' height='5px' viewBox='169 140 28 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><polygon id='Triangle' stroke='none' fill='%23a8aeb3' fill-rule='evenodd' points='169 140 197 140 183 154'></polygon></svg>"),
        $color-white;
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
    color: $color-grey-subtle;
    padding-top: 8px;
    padding-bottom: 9px;
    line-height: 22px;

    &--has-value {
        color: $color-text;
    }

    &--is-disabled,
    &--is-readonly {
        @include input-disabled();
        background: url("data:image/svg+xml;utf8,<svg width='10px' height='5px' viewBox='169 140 28 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><polygon id='Triangle' stroke='none' fill='%23a8aeb3' fill-rule='evenodd' points='169 140 197 140 183 154'></polygon></svg>"),
            $color-input-disabled;
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 50%;
    }
}

.tt-Field--has-error {
    .tt-Select {
        @include input-error();
    }
}
