.tt-View {
    position: relative;
    width: 100%;

    .tt-SubNav__wrapper {
        & + .tt-View {
            .tt-Header {
                top: 80px;
            }

            .tt-View {
                .tt-Header {
                    top: 80px;
                }
            }
        }
    }
}
