// Appear animation, Timeout: 225
.tt-appearModal-enter {
    will-change: transform;
    opacity: 0.01;

    .tt-CenterModal {
        transform: scale(0.98);
    }
}

.tt-appearModal-enter.tt-appearModal-enter-active {
    opacity: 1;
    transition: opacity 80ms ease-in;

    .tt-CenterModal {
        transition: transform 80ms ease-in-out;
        transform: scale(1);
    }
}

.tt-appearModal-exit {
    opacity: 1;

    .tt-CenterModal {
        transform: scale(1);
    }
}

.tt-appearModal-exit.tt-appearModal-exit-active {
    opacity: 0.01;
    transition: opacity 80ms ease-in;

    .tt-CenterModal {
        transition: transform 80ms ease-in;
        transform: scale(0.98);
    }
}
