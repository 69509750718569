.tt-ValueActionFieldItem {
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__value {
        color: #2d3740;
        font-size: 14px;
    }

    &__value,
    &__actions {
        display: inline-block;
    }

    &__actions {
        min-width: 190px;
    }
}
