.tt-InvoiceStatusAction {
    display: inline-block;

    .tt-ToolTip {
        display: inline-block;
        padding: 10px; // mouse area
        margin: -10px;
    }

    .tt-Icon {
        height: 100%;
    }
}
