.tt-SimpleTableCell {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    padding-right: getSpacing(2);
    font-size: 14px;
    text-align: left;
    white-space: normal;

    .tt-Label {
        margin-left: getSpacing(1);
    }

    &--is-bold {
        white-space: nowrap;
        font-weight: 600;
    }

    &--is-subtle {
        font-weight: normal;
        color: $color-text-subtle;
    }

    &--should-prevent-wrap {
        white-space: nowrap;
    }

    &--right {
        text-align: right;
    }
}
