.tt-Note {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    position: relative;
    background-color: $color-note-background;
    border-top: 2px solid $color-note-border;
    $footer-height: 45px;

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: $footer-height;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        p {
            padding: (getSpacing(2) - 5px) getSpacing(2);
            background-image: url('/static/images/note-rule-bg-pattern.png');
            background-repeat: repeat;
            font-size: 14px;
            line-height: 21px;
            min-height: 100%;
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $footer-height;
        color: $color-note-meta-text;
        font-size: 12px;
        padding: getSpacing(1) getSpacing(1.5);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .tt-Note__footer-separator {
            vertical-align: top;
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: $color-note-meta-text-separator;
            margin-left: getSpacing(1);
            margin-right: getSpacing(1);
            margin-top: 9px;
        }

        .tt-Note__edit-button {
            display: inline-block;
            position: absolute;
            right: getSpacing(1.5);
            font-size: 12px;
            background-color: $color-note-background;
            padding-left: getSpacing(1);
            opacity: 0;
            pointer-events: none;
            transform: translateX(10px);
            transition-duration: 150ms;
            transition-property: opacity, transform;

            &::before {
                content: '';
                display: block;
                pointer-events: none;
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                right: 100%;
                width: getSpacing(2);
                background: linear-gradient(to right, transparent 0%, $color-note-background 100%);
            }
        }
    }

    &:hover {
        footer {
            .tt-Note__edit-button {
                opacity: 1;
                pointer-events: auto;
                transform: none;
            }
        }
    }

    &--is-scrollable {
        &::after {
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: $footer-height;
            height: 5px;
            background: linear-gradient(to bottom, transparent 0%, rgba(black, 0.1) 100%);
        }
    }
}
