.tt-LockedFieldWrapper {
    position: relative;

    &__unlock-button {
        position: absolute;
        top: 4px;
        right: 4px;
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
    }

    &:hover {
        .tt-LockedFieldWrapper__unlock-button {
            opacity: 1;
        }
    }
}
