@import '~/stylesheets/variables/colors.scss';

.tt-TableHeaderItem {
    position: relative;
    text-align: left;
    font-weight: 600;
    color: $color-text;
    padding-bottom: 8px;
    height: 42px;

    &__toggle {
        display: flex;
        flex-direction: row;
        align-items: center;

        .tt-CheckBox__box {
            margin-top: 4px;
        }
    }

    &__button {
        cursor: pointer;
        width: 100%;
        text-align: left;
        color: $color-text;
        font-weight: 600;
        font: inherit;

        .tt-Icon {
            position: absolute;
            top: 50%;
            margin-top: -6px;
            margin-left: 0;
            color: $color-border;
            display: block;
        }

        &:hover {
            .tt-Link__content {
                text-decoration: none;
                color: $color-text;
            }
        }

        &--sorting {
            .tt-Icon {
                color: $color-black;
            }
        }
    }

    &--contains-number {
        text-align: right;

        .tt-TableHeaderItem__button {
            text-align: right;
        }
    }
}
