.tt-RevenueTableHeaderMonthItem {
    @import './mixins.scss';
    @import '~/stylesheets/variables/colors.scss';

    text-align: right;

    &--is-year-total {
        @include yearTotalShadow();
    }

    &--is-spacer {
        width: 40px;
    }
}
