.tt-ModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.tt-Modal {
    width: 100%;
    height: 100%;
    background-color: rgba(#2d373f, 0.6);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
