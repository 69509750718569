@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/mixins/clearfix.scss';

.tt-TableView {
    &__title,
    &__buttons {
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-size: 16px;
    }

    &__bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: getSpacing(1);

        .tt-TableView__title {
            > .tt-Icon,
            > span > .tt-Icon,
            > .tt-ToolTip,
            > span > .tt-ToolTip {
                margin-left: getSpacing(1) - 4px;
                vertical-align: top;
                position: relative;
                top: 2px;
            }
        }
    }

    &__buttons {
        .tt-List {
            margin: -(getSpacing(1) / 2);

            > .tt-ListItem {
                padding: (getSpacing(1) / 2);
            }
        }
    }

    &__bar,
    &__table,
    &__footer {
        padding-left: getSpacing(1.5);
        padding-right: getSpacing(1.5);
    }

    &__table {
        display: table; // to correctly adapt width
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__bar,
        &__table {
            padding-left: getSpacing(3);
            padding-right: getSpacing(3);
        }
    }

    &--full-width {
        .tt-TableView__bar,
        .tt-TableView__table {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
