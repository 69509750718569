@import '~/stylesheets/variables/colors.scss';

.tt-I18nProvider {
    height: 100%;

    &__content {
        height: 100%;
        transition: opacity 150ms ease-in;

        &-enter {
            opacity: 0.01;

            &-active {
                opacity: 1;
            }
        }

        &-leave {
            opacity: 1;

            &-active {
                opacity: 0.01;
            }
        }
    }
}
