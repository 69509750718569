.tt-inflowMomentPreviousInviteLabel {
    &__subtle {
        display: block;
        white-space: normal;
    }

    &__label {
        padding-top: 0px;
        margin-bottom: 4px;
    }
}
