@import '~/stylesheets/variables/colors.scss';

.tt-Spinner {
    display: block;
    pointer-events: none;
    transition: opacity;
    transition-duration: 100ms;

    &__label {
        display: inline-block;
        vertical-align: middle;
        padding-left: getSpacing(1);
    }

    &__spinner {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        min-width: 34px;
        min-height: 34px;
    }

    &--tiny {
        .tt-Spinner__spinner {
            min-width: 14px;
            min-height: 14px;
        }
    }

    &--small {
        .tt-Spinner__spinner {
            min-width: 20px;
            min-height: 20px;
        }
    }

    &--large {
        .tt-Spinner__spinner {
            min-width: 46px;
            min-height: 46px;
        }
    }

    &--is-overlay {
        padding: getSpacing(3);
        border-radius: 50%;

        .tt-Spinner__spinner {
            min-width: 34px + 2 * getSpacing(3);
            min-height: 34px + 2 * getSpacing(3);
        }
    }

    &--is-hidden {
        visibility: hidden;
        opacity: 0;
    }
}
