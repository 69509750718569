@import '~/stylesheets/variables/breakpoints.scss';

.tt-MobileNav {
    width: 100%;
    height: 100%;
    z-index: 12;
    position: relative;
    transition: background-color 250ms;

    .tt-List {
        position: absolute;
        width: 240px;
        background-color: $color-nav-main;
        height: 100%;
        left: -240px;
        transition: left 250ms;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    }

    &__close {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    &--is-open {
        background-color: rgba(#2d373f, 0.6);

        .tt-List {
            left: 0;
        }
    }

    &--hidden {
        display: none;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        display: none;
    }
}
