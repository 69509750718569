// Element wrapper on page
.tt-ToolTip {
    > span {
        display: inline-block;
    }
}

// Tooltip itself
.rc-tooltip {
    @import '~/stylesheets/variables/spacing.scss';

    &-inner {
        background-color: $color-tooltip-background;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        padding: (getSpacing(1) - 5px) getSpacing(1);
        max-width: 400px;
        border-radius: 2px;
    }

    &-placement-bottom &-arrow,
    &-placement-bottomLeft &-arrow,
    &-placement-bottomRight &-arrow {
        top: 2px;
        border-width: 0 7px 7px;
        border-bottom-color: $color-tooltip-background;
    }

    &-placement-top &-arrow,
    &-placement-topLeft &-arrow,
    &-placement-topRight &-arrow {
        bottom: 2px;
        border-width: 7px 7px 0;
        border-top-color: $color-tooltip-background;
    }

    &-placement-left &-arrow,
    &-placement-leftTop &-arrow,
    &-placement-leftBottom &-arrow {
        right: 2px;
        margin-top: -7px;
        border-width: 7px 0 7px 7px;
        border-left-color: $color-tooltip-background;
    }

    &-placement-right &-arrow,
    &-placement-rightTop &-arrow,
    &-placement-rightBottom &-arrow {
        left: 2px;
        margin-top: -7px;
        border-width: 7px 7px 7px 0;
        border-right-color: $color-tooltip-background;
    }

    &-placement-bottomLeft &-arrow,
    &-placement-topLeft &-arrow {
        left: 6px + getSpacing(2);
    }

    &-placement-bottomRight &-arrow,
    &-placement-topRight &-arrow {
        right: getSpacing(2);
    }

    &-placement-leftTop &-arrow,
    &-placement-rightTop &-arrow {
        top: -6px + getSpacing(2);
    }

    &-placement-leftBottom &-arrow,
    &-placement-rightBottom &-arrow {
        bottom: getSpacing(1);
    }
}
