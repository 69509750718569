.tt-EnvironmentLabel {
    pointer-events: none;
    opacity: 0.8;

    span {
        display: inline-block;
        background-color: rgba(black, 0.9);
        border-radius: 5px 5px 0 0;
        padding: 5px 80px;
        color: white;
        font-size: 14px;
        font-weight: 600;
    }
}
