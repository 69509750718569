.tt-LearnerGroupParticipationHistoryDisenrollReason {
    @import '~/stylesheets/variables/colors.scss';

    display: block;
    background-color: $color-light-perrywinkle;
    font-size: 12px;
    padding: 3px 8px;
    max-width: 480px;
    line-height: 18px;

    &__reason {
        color: $color-text-subtle;
    }
}
