@import '~/stylesheets/variables/spacing.scss';

.tt-TimelineInput {
    .tt-TimelineMessageInput {
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &__bottom {
        position: relative;
        margin-top: getSpacing(1);
        opacity: 0;
        pointer-events: none;
        transform: translate3d(0, -100%, 0);
        $d: 150ms;
        transition: opacity $d, transform $d ease $d, margin $d ease $d, max-height $d ease $d;
        z-index: 0;
        overflow: hidden;

        .tt-TimelineInput__targets {
            margin-top: getSpacing(2);

            .tt-MultiInput {
                padding-top: 0;

                .tt-MultiInput__item {
                    margin-bottom: getSpacing(0.5);
                }
            }
        }

        &:not(&--is-active) {
            margin-top: 0;
            max-height: 0;
        }

        &--is-active {
            opacity: 1;
            transform: none;
            pointer-events: auto;
            max-height: 41px;
            transition: opacity $d ease $d, transform $d, margin $d, max-height $d;
        }

        &--has-target-selection {
            &.tt-TimelineInput__bottom--is-active {
                max-height: 123px;
            }
        }
    }
}
