.tt-InviteTextVariableLabel {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    display: inline-flex;
    align-items: center;
    position: relative;
    border-radius: 1px;
    font-size: 12px;
    color: $color-grey-dark;
    background-color: $color-text-variable-button-background;
    border: 0;
    // margin-left: getSpacing(1, -4px);
    padding-left: 8px;
    padding-top: 2px;
    padding-right: 10px;
    min-height: 24px;
}
