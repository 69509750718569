.tt-TeacherAvailability {
    @import '~/stylesheets/variables/spacing.scss';

    .tt-TeacherAvailability__header-cell,
    .tt-TeacherAvailability__teacher-header-cell {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }

    tbody + thead tr:first-child {
        .tt-TeacherAvailability__header-cell,
        .tt-TeacherAvailability__teacher-header-cell {
            padding-top: getSpacing(1);
        }
        .tt-TeacherAvailability__teacher-header-cell {
            visibility: hidden;
        }
    }

    .tt-TeacherAvailability__dates-days {
        border-bottom: 3px solid #e1e3e6;
        .tt-TeacherAvailability__header-cell {
            font-size: 12px;
            font-weight: 300;
        }
    }

    .tt-TeacherAvailability__dates-days .tt-TeacherAvailability__header-cell--current-week {
        color: #2490e7;
        font-weight: 600;
    }

    td {
        position: relative;
        color: #909599;
        font-size: 14px;
        vertical-align: middle;
    }

    .tt-TeacherAvailability__teacher-cell {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
        a {
            font-weight: 600;
        }
    }

    .tt-TeacherAvailability__cell {
        padding: 0;
        margin: 0;
        padding: 2px;
        border-left: 1px solid #e1e4e6;

        &__empty-cell {
            margin: 1px;
            background: #f2f4f5;
            height: 34px;
        }

        &--isMonday {
            border-left-width: 2px;
            border-left-color: #d5d6d8;
        }

        > div {
            margin: 1px;
            background: #f2f4f5;
            height: 34px;
            table {
                width: 100%;
            }
            td {
                padding: 0 1px;
            }
        }
        .tt-TeacherAvailability__indicator {
            // The availibilty presence bar
            border-radius: 2px;
            height: 34px;
            background-color: #f2f4f5;
            &--partial-availability {
                background-color: #97e0b9;
            }
            &--no-availability {
                background-color: #e1e4e6;
            }
            &--full-availability {
                background-color: #2bc572;
            }
        }

        &--has-availability > div {
            background: transparent;
        }
    }
}
