.tt-ValidationError {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    position: relative;
    display: block;
    max-width: 500px;
    border-radius: 2px;
    background-color: rgba($color-red, 0.2);
    color: $color-text;
    padding: getSpacing(0.5) getSpacing(1);

    p {
        padding-left: getSpacing(2);
    }

    .tt-Icon {
        position: absolute;
        top: 8px;
        left: getSpacing(1);
        display: inline-block;
        color: $color-red;
    }

    &--is-warning {
        background-color: rgba($color-orange, 0.2);

        .tt-Icon {
            color: $color-orange;
        }
    }

    &--full-width {
        max-width: 100%;
    }
}
