@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/variables/breakpoints.scss';

.tt-TableWrap {
    padding-top: getSpacing(1);
    min-width: 100%;

    .tt-TableView {
        margin-bottom: 0;
    }

    .tt-ActionBar,
    .tt-Highlight {
        margin-bottom: getSpacing(2);
    }

    &--inset-table {
        padding-bottom: getSpacing(1.5);
        background-color: $color-background-table;
        border-bottom: 1px solid #e1e4e6;
    }

    .tt-TableView + .tt-ActionBar {
        margin-top: getSpacing(2);
    }

    &--contains-dropdowns {
        padding-bottom: 200px;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        padding-top: getSpacing(3);

        &--inset-table {
            padding-bottom: getSpacing(3);
        }
    }
}
