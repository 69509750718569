.tt-BreadCrumbs {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/fonts.scss';
    @import '~/stylesheets/variables/breakpoints.scss';

    font-family: $font-primary;
    display: flex;
    flex-direction: column;

    .tt-HeaderTitle {
        margin-top: 20px;
        margin-bottom: 14px;
    }

    .tt-Crumb {
        .tt-Link:hover .tt-Link__content {
            text-decoration: none;
            color: rgba($color-text, 0.7);
        }

        &::after {
            content: '\00a0';
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><polygon fill='%23C4C8CC' points='4 12.735 5.265 14 11.265 8 5.265 2 4 3.265 8.735 8'/></svg>"),
                $color-background;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 100%;
            width: 9px;
            margin-right: getSpacing(1) / 2;
            margin-left: getSpacing(1) / 2;
            color: $color-grey-subtle;
            display: inline-block;
        }
    }

    &--has-crumbs {
        .tt-HeaderTitle {
            margin-top: 8px;
            margin-bottom: 4px;

            &.tt-HeaderTitle--is-loading {
                margin-top: 0;
            }
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-HeaderTitle {
            margin-bottom: 10px;
        }

        &--has-crumbs {
            .tt-HeaderTitle {
                margin-bottom: 0;
            }
        }
    }
}
