.tt-DUOResponseDetailsModal {
    @import '~/stylesheets/variables/spacing.scss';

    .tt-TableView__table {
        margin-top: getSpacing(1);
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-TableView__table {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
