.tt-MultiInput {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/breakpoints.scss';
    @import '~/stylesheets/mixins/input.scss';

    &__item:not(:last-child) {
        margin-bottom: getSpacing(0.5);

        .tt-Hint {
            margin-bottom: getSpacing(1);
        }
    }

    &-inline {
        .tt-MultiInput__item {
            margin-right: getSpacing(0.5);
        }
    }

    &-select-range {
        .tt-MultiInput__item:nth-child(1) {
            width: 155px;
        }
        .tt-MultiInput__item:nth-child(2) {
            padding-left: 10px;
            padding-right: 10px;
            display: inline-block;
            color: $color-text-subtle;
            margin-top: 8px;
            margin-bottom: 17px;
        }
        .tt-MultiInput__item:nth-child(3) {
            width: 155px;
        }
    }

    &-name,
    &-address,
    &-phone {
        text-align: left;

        .tt-MultiInput__item {
            width: 100%;
            float: none;
        }
    }

    &-radio {
        padding-top: getSpacing(1);
        min-height: 41px;

        .tt-MultiInput__item {
            margin-right: getSpacing(3);
            margin-bottom: getSpacing(2);

            .tt-RadioButton + .tt-FieldCollection {
                margin-top: $spacing-base;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: getSpacing(1);
            }
        }

        &.tt-MultiInput--is-horizontal {
            .tt-MultiInput__item {
                margin-bottom: getSpacing(0.5);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-checkbox {
        padding-top: getSpacing(1);
        padding-bottom: 0;
        min-height: 41px;

        .tt-MultiInput__item {
            margin-bottom: getSpacing(1);
            margin-right: getSpacing(2, -4);

            input[type='text'] {
                margin-left: getSpacing(2);
                width: calc(100% - #{getSpacing(2)});
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &--precision-date {
        .tt-MultiInput__item {
            margin-right: getSpacing(0.5);
            margin-bottom: 0;

            &:nth-child(1) {
                width: 80px;
            }
            &:nth-child(2) {
                width: 120px;
            }
            &:nth-child(3) {
                width: 88px;
            }
        }
    }

    &--date-time {
        .tt-MultiInput__item {
            margin-right: getSpacing(0.5);
            margin-bottom: 0;

            &:nth-child(1) {
                width: 150px;
            }
            &:nth-child(2) {
                width: 96px;
            }
        }
    }

    &-email-name {
        display: flex;
        justify-content: space-between;

        .tt-MultiInput__item {
            flex: 1;
        }

        .tt-MultiInput__item:nth-child(1) {
            margin-right: 8px;
        }
    }

    .tt-MultiInput-email-name.tt-MultiInput--is-horizontal {
        &::after {
            content: none !important;
        }
    }

    &--frequency {
        .tt-MultiInput__item {
            margin-right: getSpacing(0.5);
            margin-bottom: 0;

            &:nth-child(1) {
                width: 240px;
            }
            &:nth-child(2) {
                width: 96px;
            }
        }
    }

    &--half {
        .tt-MultiInput__item {
            width: 49%;
            &:first-child {
                margin-right: 2%;
            }
        }
    }

    &--two-third {
        .tt-MultiInput__item {
            margin-right: getSpacing(0.5);
            margin-bottom: 0;

            &:first-child {
                width: 245px;
            }

            &:nth-child(2) {
                width: 150px;
                margin-right: 0;
            }
        }
    }

    &--is-horizontal {
        text-align: right;

        > .tt-MultiInput__item {
            display: inline-block;
            float: left;
            text-align: left;

            &--right {
                float: none;
            }
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        max-width: $input-max-width;

        &--full-width {
            max-width: 100%;

            &.tt-MultiInput-radio {
                .tt-MultiInput__item {
                    margin-right: 0;
                }
            }
        }

        &-name {
            .tt-MultiInput__item {
                margin-right: getSpacing(0.5);
            }
            .tt-MultiInput__item:nth-child(1) {
                max-width: calc(75% - 6px);
                width: 354px;
            }
            .tt-MultiInput__item:nth-child(2) {
                max-width: 25%;
                width: 120px;
                margin-right: 0;
            }
            .tt-MultiInput__item:nth-child(3) {
                max-width: 25%;
                width: 120px;
                margin-bottom: 0;
            }
            .tt-MultiInput__item:nth-child(4) {
                max-width: calc(75% - 6px);
                width: 354px;
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        &-address {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .tt-MultiInput__item {
                margin-right: getSpacing(0.5);
            }
            .tt-MultiInput__item:nth-child(1) {
                flex: 6 1 50%;
            }
            .tt-MultiInput__item:nth-child(2) {
                flex: 3 1 25%;
            }
            .tt-MultiInput__item:nth-child(3) {
                flex: 1 1 15%;
                margin-right: 0;
            }
            .tt-MultiInput__item:nth-child(4) {
                flex: 2 1 calc(30% - 10px);
            }
            .tt-MultiInput__item:nth-child(5) {
                margin-right: 0;
                flex: 1 1 calc(60% - 10px);
            }
        }

        &-phone {
            display: flex;

            .tt-MultiInput__item {
                margin-bottom: 0;

                + .tt-MultiInput__item {
                    margin-left: getSpacing(0.5);
                    width: 35%;
                }
            }
        }
    }
}
