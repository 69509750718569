@import '~/stylesheets/variables/colors.scss';

.tt-DatePicker {
    max-width: 150px;

    &--has-icon {
        .tt-Icon {
            position: absolute;
            padding: 11px;
            color: $color-grey-subtle;
        }

        .tt-Input[type='date'] {
            width: 125%;
            padding-left: 35px;
        }
    }
}
