.tt-RevenueTableHeader {
    .tt-TableHeader__row {
        border-bottom-color: #c4c8cc;
    }

    .tt-TableCell {
        background-color: #e1e3e6;
        padding-top: 7px;
        padding-bottom: 7px;
        height: 36px;
    }
}
