.tt-ReadableDateFromNow {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    .tt-Icon {
        position: relative;
        top: 2px;
        margin-right: getSpacing(0.5);
    }

    &--has-warning {
        color: $color-red;
    }
}
