@import '~/stylesheets/animations/showHideNav.scss';

.tt-AppView {
    height: 100%;
    position: relative;
    padding-top: 160px;
    padding-bottom: 48px;

    .tt-MainNav {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: $menu-transition;
    }

    .tt-MobileNav {
        position: fixed;
        top: 0;
    }

    &--loading {
        text-align: center;

        .tt-Spinner {
            display: inline-block;
        }
    }
}
