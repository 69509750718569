.tt-StatisticTable {
    @import '~/stylesheets/variables/colors.scss';

    border: 1px solid #c4c8cc;
    border-radius: 1px;
    background-color: #ffffff;

    .tt-TableRow--is-statistic-row {
        background-color: #f2f4f5;

        .tt-Subtle {
            font-size: 14px;
        }
    }
}
