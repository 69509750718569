.tt-ButtonGroup {
    @import '~/stylesheets/variables/spacing.scss';

    display: flex;
    align-items: center;

    &--is-separated {
        > .tt-ListItem + * {
            margin-left: getSpacing(1);
        }

        &.tt-ButtonGroup--small-buttons {
            > .tt-ListItem + * {
                margin-left: getSpacing(0.5);
            }
        }
    }

    &--is-joined {
        > .tt-ListItem:first-child {
            .tt-Button,
            .tt-FileInput {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        > .tt-ListItem + * {
            .tt-Button,
            .tt-FileInput {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
