.tt-FieldBlock {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/breakpoints.scss';
    @import '~/stylesheets/variables/colors.scss';

    width: 100%;
    list-style-type: none;

    padding: 17px;
    width: 100%;
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid $color-grey-light;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.04);

    &:last-child {
        margin-bottom: 10px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    &__title {
        color: $color-grey-dark;
        font-size: 14px;
        font-weight: 600;
        padding-top: 0;
    }

    &__list {
        .tt-Field {
            &__wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .tt-Field {
        width: 100%;
    }

    .tt-ValidationError {
        margin-top: getSpacing(1);
    }

    &--is-form {
        .tt-Field {
            margin-bottom: getSpacing(1);

            &_title {
                padding-top: getSpacing(1);
                padding-bottom: getSpacing(1);
                line-height: 18px;
                display: block;
            }

            &__content {
                display: block;
            }
        }
    }
    &--is-without-border {
        background-color: lightgray;
        border: 0px;
        box-shadow: 0;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &--is-form {
            .tt-Field {
                margin-bottom: getSpacing(1);

                &_title {
                    padding-top: getSpacing(1) !important;
                    padding-bottom: getSpacing(1);
                    line-height: 18px;
                    display: block;
                }

                &__content {
                    display: block;
                }
            }
        }
    }
}
