.tt-AttendanceTableHeaderItem {
    width: 61px;
    padding: 5px 3px;
    height: 20px;

    & a {
        font-size: 9px;
        font-weight: 700;
    }

    .tt-ToolTip {
        span {
            display: unset !important;
        }
    }
}
