@import '~/stylesheets/variables/colors.scss';

.tt-InflowStatusBar {
    width: 100%;
    padding: 6px 12px 12px 12px;
    border: 1px solid #c4c8cc;
    margin-bottom: 30px;
    position: relative;

    .tt-SimpleTable {
        width: 100%;

        .tt-SimpleTableCell {
            width: 300px;

            &--is-bold {
                width: 200px;
            }
        }
    }

    .tt-SimpleTable .tt-SimpleTableRow + .tt-SimpleTableRow > .tt-SimpleTableCell {
        padding-top: 0;
    }

    .tt-Button {
        position: absolute;
        right: 12px;
        top: 12px;
    }
}
