@import '~/stylesheets/variables/colors.scss';
@import '~/stylesheets/variables/spacing.scss';

.tt-MainNavItem {
    display: inline-block;

    &--right {
        float: none;

        .tt-ExpandableMenu {
            margin-top: 6px;

            @media screen and (min-width: $breakpoint-tablet) {
                margin-top: 0;

                span {
                    padding-top: 5px;
                }

                &__dropdownlist {
                    left: -50%;
                    top: 33px;

                    span {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
