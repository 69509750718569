/* This stylesheet generated by Transfonter (http://transfonter.org) on January 19,
    2017 1:50 PM */

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-SemiboldItalic.eot');
    src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
        url('/static/fonts/OpenSans/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-SemiboldItalic.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-SemiboldItalic.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans.eot');
    src: local('Open Sans'), local('OpenSans'),
        url('/static/fonts/OpenSans/OpenSans.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-ExtraboldItalic.eot');
    src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'),
        url('/static/fonts/OpenSans/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-ExtraboldItalic.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-ExtraboldItalic.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-ExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-Italic.eot');
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
        url('/static/fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-Extrabold.eot');
    src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
        url('/static/fonts/OpenSans/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-Extrabold.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-Extrabold.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-Semibold.eot');
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
        url('/static/fonts/OpenSans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-Semibold.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-BoldItalic.eot');
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
        url('/static/fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSansLight-Italic.eot');
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
        url('/static/fonts/OpenSans/OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSansLight-Italic.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSansLight-Italic.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSansLight-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-Bold.eot');
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('/static/fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/fonts/OpenSans/OpenSans-Light.eot');
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('/static/fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
        url('/static/fonts/OpenSans/OpenSans-Light.woff') format('woff'),
        url('/static/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
