.tt-GroupExamsModalTable {
    @import '~/stylesheets/variables/spacing.scss';

    .tt-TableRow--has-inline-button {
        .tt-TableCell {
            overflow: visible;
            height: auto;
        }
    }

    .tt-TableRow {
        // .tt-FileInput,
        .tt-ExamDownloader__download-button {
            opacity: 0;
            pointer-events: none;
            transition: all 175ms ease-in-out;
        }

        // &:hover .tt-FileInput,
        &:hover .tt-ExamDownloader__download-button {
            opacity: 1;
            pointer-events: initial;
        }

        .tt-FileInput--has-value {
            opacity: 1;
            pointer-events: initial;
        }
    }

    .tt-FileInput {
        display: inline-flex;
        flex-direction: column;

        .tt-Button {
            margin-top: 0;
        }

        &__filename {
            margin-top: 5px;
        }
    }

    &--type-is-score,
    &--type-is-level {
        .tt-TableRow--has-inline-button {
            .tt-TableCell {
                padding: 3.5px getSpacing(1.5);

                &--has-large-input {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    max-width: unset;

                    .tt-List {
                        flex-grow: 1;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }

                    .tt-FileInput {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    &--type-is-score {
        .tt-ScoreInput {
            margin-right: getSpacing(1);
        }
    }

    &--type-is-result {
        .tt-TableRow--has-inline-button {
            .tt-TableCell {
                padding: 8px getSpacing(1.5);
            }
        }

        .tt-MultiInput {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            margin-right: getSpacing(2);
            padding-top: 0;
            min-height: auto;

            &__item {
                margin-bottom: 0;

                + .tt-MultiInput__item {
                    margin-right: getSpacing(2);
                }

                + .tt-MultiInput__item:last-child {
                    margin-right: 0;
                }
            }

            .tt-RadioButton {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    &--type-is-level {
        .tt-TableRow .tt-TableCell:nth-child(3) .tt-TagPicker {
            margin-right: getSpacing(0.5);
            min-width: 178px;
        }
    }
}
