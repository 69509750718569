// view files are for page specific styling
@import '~/stylesheets/variables/colors.scss';
@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/animations/showHideNav.scss';

.tt-RootView {
    .tt-EnvironmentLabel {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 999;
        transform: translateY(40px);
        animation: slideUp 200ms ease 1s;
        animation-fill-mode: forwards;

        @keyframes slideUp {
            0% {
                transform: translateY(40px);
            }

            100% {
                transform: none;
            }
        }
    }

    &--hide-main-nav {
        .tt-MainNav,
        .tt-SubNav,
        .tt-Header {
            transform: translateY(-48px);
            transition: $menu-transition;
        }
    }
}
