.tt-Paragraph {
    font-size: 14px;
    color: $color-text;

    strong:not(.tt-Bold) {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    &--is-success-message {
        color: $color-text-success;

        .tt-Paragraph__success-icon {
            display: inline-block;
            vertical-align: top;
            margin-top: 2px;
            margin-right: getSpacing(1);
        }
    }
}
