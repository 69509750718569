.tt-VideosModal {
    @import '~/stylesheets/variables/spacing.scss';

    .tt-CenterModal__window__content {
        padding: getSpacing(3);
    }

    .tt-VideosList {
        margin-top: getSpacing(3);
    }
}
