@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/variables/colors.scss';
@import '~/stylesheets/mixins/clearfix.scss';
@import '~/stylesheets/variables/breakpoints.scss';

.tt-FilterFieldCollection {
    background-color: $color-filter-background;
    padding: getSpacing(1) getSpacing(1.5) 6px;
    border-radius: 2px;

    .tt-Field {
        margin: 0;
        text-align: left;
        margin-right: getSpacing(1);
        padding-bottom: getSpacing(1) - 12px;
        display: inline-block;
        float: left;

        &__title {
            width: 100%;
            color: $color-text;
            padding: 0;
            font-weight: 600;
            padding-bottom: getSpacing(1) - 12px;
            margin-bottom: 0;
        }

        &__content {
            width: 100%;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .tt-Field {
            width: calc(#{100% / 3} - #{getSpacing(2) / 3});
        }
    }

    @include clearfix();
}
