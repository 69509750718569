.tt-Column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &--horizontal-center {
        align-items: center;
    }

    &--vertical-center {
        justify-content: center;
    }
}
