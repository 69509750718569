.tt-ExamAbilityField {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/breakpoints.scss';

    display: flex;
    flex-direction: column;
    padding: getSpacing(2);
    background: #f2f4f5;
    margin-top: getSpacing(1);
    max-width: 492px;

    &__title {
        max-width: calc(252px - #{getSpacing(2)});
        padding: getSpacing(0.5) getSpacing(2) getSpacing(0.5) 0;
        width: 100%;
        color: $color-text-subtle;
    }

    @media screen and (min-width: $breakpoint-desktop) {
        max-width: 720px;
        // margin-left: -252px;
        // width: 100vw;
    }
}
