.tt-Icon {
    @import '../variables/colors.scss';

    display: inline-block;
    font-size: 16px;

    svg {
        height: 1em;
    }

    &--counter-icon {
        font-size: 10px;
        width: 18px;
        height: 18px;
        background: #e87b51;
        color: #fff !important;
        border-radius: 50%;
        line-height: 18px;
        text-align: center;
        text-indent: -1px;

        &--red-square {
            display: inline-block;
            background-color: $color-square-counter-icon;
            border-radius: 2px;
            color: $color-white;
            font-size: 12px;
            min-width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            padding: 0px 3px;
        }
    }
}
