.tt-TimelineItem {
    @import '~/stylesheets/variables/colors.scss';

    display: flex;
    position: relative;
    width: 100%;
    padding-left: 20px;

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding-bottom: 20px;
    }

    &__circle {
        background-color: #fff;
        border: 1px solid $color-button-border;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: -25px;
        width: 12px;
        height: 12px;
        z-index: 2;
    }

    &__regular-item {
        height: 100%;
        border-left: 1px solid $color-button-border;
        position: absolute;
        left: -19.5px;
        top: 5px;
        z-index: 1;
    }

    &__last {
        height: 1px;
        border-left: 1px solid $color-button-border;
        position: absolute;
        left: -19.5px;
        z-index: 1;
    }

    &__first {
        height: 100%;
        border-left: 1px solid $color-button-border;
        position: absolute;
        left: -19.5px;
        top: 5px;
        z-index: 1;
    }

    &__no-date {
        height: 100%;
        border-left: 1px dashed $color-button-border;
        position: absolute;
        left: -19.5px;
        top: 5px;
        z-index: 1;
    }
}
