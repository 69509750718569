.tt-StatisticTableRow {
    @import '~/stylesheets/variables/colors.scss';

    border-bottom: 0;

    + .tt-StatisticTableRow {
        border-top: 1px solid #e1e3e6;
    }

    .tt-StatisticTableCell {
        & + .tt-StatisticTableCell {
            border-left: 1px solid $color-light-perrywinkle;
        }
    }
}
