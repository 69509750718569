.tt-HistoryLog {
    .tt-SimpleTableCell {
        padding-top: 0;
        padding-right: 12px;
    }

    .tt-SimpleTableRow {
        + .tt-SimpleTableRow {
            > .tt-SimpleTableCell {
                padding-top: 0;
            }
        }
    }
}
