.tt-InviteTextInput {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    &__text-variables {
        padding-bottom: getSpacing(3, -4px);
        display: flex;
        flex-wrap: wrap;
    }

    &__language-switch {
        padding-bottom: getSpacing(2, -4px);
    }

    &__variable-button {
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 1px;
        font-size: 12px;
        color: $color-grey-dark;
        background-color: $color-text-variable-button-background;
        border: 0;
        margin: 0 getSpacing(1, -4px) getSpacing(1, -4px) 0;
        padding-left: 8px;
        padding-top: 2px;
        padding-right: 10px;
        min-height: 24px;
        cursor: pointer;

        &--active {
            padding-left: 4px;
            background-color: $color-text-variable-button-background-active;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color-text-variable-button-background-active;
        }
    }

    &__variable-button-icon {
        display: flex;
        margin-right: getSpacing(1, -4px);
        margin-top: -2px;
    }

    &__textarea {
        &--is-hidden {
            display: none;
        }
    }

    &__errors {
        font-size: 12px;
        margin-top: getSpacing(1, 2px);
    }

    &__error {
        color: $color-orange;
    }
}
