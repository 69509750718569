.tt-ProcessList {
    &__title {
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-size: 16px;
    }

    .tt-ProcessItem + .tt-ProcessItem {
        margin-top: 20px;
    }

    .tt-LearnerGroupParticipationHistoryDisenrollReason {
        margin-top: 4px;
    }

    &__title-header {
        padding-left: 10px;
    }
}
