.tt-Button {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/fonts.scss';

    display: inline-block;
    position: relative;
    padding: 2px getSpacing(1.5);
    height: 41px;
    border-radius: 2px;
    font-size: 14px;
    font-family: $font-primary;
    line-height: 1em;
    background-color: $color-button;
    border: 1px solid $color-button-border;
    font-weight: 600;
    color: $color-button-text;
    cursor: pointer;
    transition-duration: 200ms;
    transition-property: opacity, padding, transform;
    white-space: nowrap;

    .tt-Spinner {
        position: absolute;
        right: getSpacing(1.5);
        top: 50%;
        margin-top: -10px;
        pointer-events: none;
    }

    &:hover {
        background-color: #f3f4f5;
    }

    &__left-icon,
    &__right-icon {
        display: inline-block;
        vertical-align: middle;

        .tt-Icon {
            color: $color-button-border;
        }
    }

    &__left-icon + &__content,
    &__content + &__right-icon {
        margin-left: getSpacing(1) - 3px;
    }

    &--is-loading {
        padding-right: 48px;
        pointer-events: none;
        opacity: 0.8;

        .tt-Spinner {
            margin-top: -10px;
        }
    }

    &:active,
    &--is-active {
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    &--is-active {
        background-color: #f3f4f5;
    }

    &-in-row {
        padding: 0px;
        height: 32px;
        width: 32px;
        margin-top: -4px;
        margin-bottom: -10px;
    }

    &-in-row-large {
        padding: 0 12px;
        height: 32px;
        width: auto;
        margin-top: -4px;
        margin-bottom: -10px;
    }

    &-edit {
        color: $color-button-text-inverted;
        background-color: $color-button-edit;
        border-color: $color-button-border-edit;
        &:hover {
            background-color: $color-button-edit-hover;
        }

        .tt-Icon {
            color: $color-button-text-inverted;
        }
    }

    &-submit {
        color: $color-button-text-inverted;
        background-color: $color-button-submit;
        border-color: $color-button-border-submit;
        &:hover {
            background-color: $color-button-submit-hover;
        }

        .tt-Icon {
            color: $color-button-text-inverted;
        }
    }

    &-danger {
        color: $color-button-text-inverted;
        border-color: $color-button-border-danger;
        background-color: $color-button-danger;
        &:hover {
            background-color: $color-button-danger-hover;
        }

        .tt-Icon {
            color: $color-button-text-inverted;
        }
    }

    &--secondary-danger {
        color: $color-button-text-danger;
        border-color: $color-button-border-danger;

        &:hover {
            background-color: rgba($color-button-danger, 0.05);
        }
    }

    &--secondary-submit {
        border-color: $color-button-border-secondary-submit;
        background-color: $color-button;
        color: $color-text-button-secondary-submit;

        &:hover {
            background-color: rgba($color-button-submit, 0.1);
            border-color: $color-button-border-submit;
        }
    }

    &--is-disabled {
        pointer-events: none;
    }

    &--is-disabled,
    &--looks-disabled {
        &,
        &:hover,
        &:active {
            background-color: $color-input-disabled;
            border-color: $color-border-input;
            color: $color-grey-subtle;
            box-shadow: none;
        }

        &.tt-Button-edit {
            color: rgba($color-button-text-inverted, 0.5);
            background-color: $color-button-edit;
            border-color: $color-button-border-edit;
        }
    }

    &--is-small {
        height: 32px;
        padding-left: getSpacing(1);
        padding-right: getSpacing(1);

        .tt-Button__left-icon,
        .tt-Button__content {
            vertical-align: top;
        }

        .tt-Button__left-icon + .tt-Button__content,
        .tt-Button__content + .tt-Button__right-icon {
            margin-left: 7px;
        }

        .tt-Spinner {
            right: getSpacing(1);
        }

        &.tt-Button--is-loading {
            padding-right: 42px;
        }
    }

    &--has-notification {
        &::after {
            content: '';
            position: absolute;
            top: -4px;
            right: -3px;
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: $color-blue-dark;
        }
    }
}
