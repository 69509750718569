.tt-RadioButton {
    @import '~/stylesheets/variables/spacing.scss';

    display: flex;

    cursor: pointer;

    > * {
        pointer-events: none;
    }

    .tt-Input {
        display: none;
    }

    &__box,
    &__text {
        display: inline-block;
        vertical-align: top;
    }

    &__box {
        margin-right: getSpacing(1);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #ffffff;
        padding: 3px;
        border: 1px solid #909599;

        &__circle {
            display: block;
            opacity: 0;
            text-align: center;
            background-color: #2490e7;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            transform: scale(0);
            transition: opacity 100ms, transform 100ms;
        }
    }

    &__text {
        line-height: 22px;
        margin-top: -3px;
    }

    &:hover {
        .tt-RadioButton__box__circle {
            opacity: 0.5;
            transform: scale(0.75);
        }
    }

    .tt-Input:checked + .tt-RadioButton__box {
        .tt-RadioButton__box__circle {
            opacity: 1;
            transition: opacity 100ms, transform 0ms;
            transform: scale(1);
        }
    }

    &--is-disabled,
    &--is-readonly {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
            .tt-RadioButton__box__circle {
                opacity: 0;
                transform: scale(0);
            }
        }
    }
}

.tt-Field--has-error {
    .tt-RadioButton {
        .tt-RadioButton__box {
            box-shadow: 0 0 0px 1px $color-input-border-error inset;
        }
    }
}
