.tt-PageView {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/breakpoints.scss';

    padding-bottom: getSpacing(3);

    @media screen and (min-width: $breakpoint-tablet) {
        padding-bottom: getSpacing(4);
    }
}
