@import '~/stylesheets/variables/colors.scss';
@import '~/stylesheets/mixins/input.scss';

.tt-ErrorLabel {
    display: block;
    font-size: 12px;
    color: #8d9ebd;

    &--is-error-message {
        display: flex;
        background-color: $color-label-error-background;
        color: $color-text;
        max-width: $input-max-width;
        border-radius: 2px;
        padding: 10px 15px;
        font-size: 14px;

        .tt-Icon {
            color: #e95055;
            padding-top: 2px;
            padding-right: 10px;
        }
    }
}
