.tt-EmptyView {
    @import '~/stylesheets/variables/spacing.scss';

    padding: getSpacing(2) 0;

    &__image {
        margin-right: getSpacing(2);
    }

    &__title {
        font-size: 1.4em;
    }

    &__action {
        margin-top: getSpacing(1);

        .tt-Button .tt-Icon,
        .tt-Link .tt-Icon {
            font-size: 12px;
        }
    }
}
