@import '~/stylesheets/mixins/input.scss';

.tt-Input {
    @include input();
    height: 41px;

    &--is-disabled,
    &--is-readonly {
        @include input-disabled();
    }
}

.tt-Field--has-error {
    .tt-Input,
    .tt-TagPicker .tt-TagPicker__control {
        @include input-error();
    }
}
