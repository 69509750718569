.tt-UnitInput {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/mixins/input.scss';

    display: flex;
    max-width: $input-max-width;

    .tt-Input {
        width: auto;
        max-width: none;
        flex: 1 0;
        order: 2;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &__icon {
        padding-left: 30px;
        font-size: 14px;
        padding: 8px getSpacing(1);
        font-weight: 600;
        order: 1;
        border: 1px solid $color-border-input;
        border-right: 0;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        background: #fff;
    }

    &--align-unit-right {
        .tt-Input {
            order: 1;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .tt-UnitInput__icon {
            order: 2;
            border-right: 1px solid $color-border-input;
            border-left: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
