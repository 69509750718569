.tt-RevenueTableTotalsRow {
    .tt-RevenueAmountTableCell {
        background-color: #f2f4f5;
        font-weight: 600;

        &--is-future-month {
            font-weight: normal;
        }
    }
}
