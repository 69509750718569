.tt-HorizontalScrollView {
    max-width: 100vw;
    position: relative;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;

    // hide scrollbar
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &--needs-padding-for-tagpicker {
        padding-bottom: 250px;
    }
}
