.tt-HeaderAction {
    @import '~/stylesheets/variables/spacing.scss';

    display: flex;
    align-items: center;

    &__label {
        margin-right: getSpacing(1);
    }
}
