.tt-TableRow {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    border-bottom: 1px solid $color-border;

    .tt-TableRow__show-on-row-hover.tt-TableRow__button-expansion-icon {
        opacity: 0;
    }

    &:nth-last-child(2) {
        .tt-ExamProgressCell--is-blocked {
            border-bottom: 1px solid $color-border;
        }
    }

    &__expansion {
        border-bottom: 1px solid $color-border;
        background-color: $color-grey-background;

        .tt-TableCell {
            color: $color-text;
            height: 49px - getSpacing(1);
        }
    }

    &--is-seamless {
        border-bottom: 0;

        .tt-TableRow__expansion {
            background-color: transparent;

            .tt-TableCell {
                padding-top: 0;
            }
        }
    }

    &--is-action-row {
        border-bottom: 0;

        .tt-Subtle {
            font-size: 14px;
            // margin-top: 4px;
            margin-left: 0;
        }

        .tt-TableCell {
            border-top: 1px solid $color-border;
            padding-top: 12px;
            padding-bottom: 8px;
            padding-right: 8px;
            padding-left: 18px;
        }

        .tt-ListItem:nth-child(2) {
            margin-left: getSpacing(1);
        }

        .tt-Button {
            margin-top: -4px;
        }
    }

    &--is-highlighted {
        background-color: $color-background-table;
    }

    &--is-expandable {
        .tt-TableRow__button {
            text-align: right;
        }

        .tt-TableRow__button-expansion-icon {
            position: relative;
            opacity: 1;
            top: 3px;
            color: $color-grey-subtle;
        }

        &:hover {
            background-color: $color-background-table;
            cursor: pointer;
            .tt-TableRow__button-expansion-icon {
                color: inherit;
            }
        }

        &.tt-TableRow--is-expanded {
            background-color: $color-background-table;

            .tt-TableRow__button-expansion-icon {
                color: inherit;
            }
        }

        &:hover {
            background-color: $color-background-table;
        }
    }

    &--highlight {
        background-color: $color-background-table;
        font-weight: bold;
    }

    &--is-statistic-row {
        border-bottom: 0;

        .tt-Subtle {
            margin-left: 0;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__show-on-row-hover {
            opacity: 0;
        }

        &:hover {
            .tt-TableRow__show-on-row-hover {
                opacity: 1;
            }
        }
    }
}
