.tt-InviteTextViewer {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';

    &__text {
        font-size: 14px;
        white-space: pre-line;
    }

    &__language-switch {
        margin-bottom: getSpacing(4, -4px);
    }

    &__variable {
        margin-left: getSpacing(1, -4px);
    }

    &--border {
        border: 1px solid $color-light-perrywinkle;
        padding: getSpacing(4, -8px);

        .tt-InviteTextViewer__text {
            max-width: 600px;
        }
    }
}
