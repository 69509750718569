.tt-TableCell.tt-RevenueAmountTableCell {
    @import './mixins.scss';
    @import '~/stylesheets/variables/colors.scss';

    text-align: right;

    &--is-placeholder {
        color: $color-text-subtle;
    }

    &--is-year-total {
        font-weight: 600;
        @include yearTotalShadow();
    }

    &--is-future-month {
        color: $color-text-subtle;
    }

    &--is-current-month {
        background-color: #f2f4f5;
    }
}
