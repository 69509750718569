.tt-InlineInputs {
    @import '~/stylesheets/variables/breakpoints.scss';

    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &__item {
        min-width: 50%;
        padding: 12px;
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__item {
            min-width: 33.3%;
        }
    }
}
