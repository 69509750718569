.tt-Article {
    display: block;
    padding-top: getSpacing(3);

    .tt-Paragraph {
        + .tt-Paragraph {
            margin-top: getSpacing(2);
        }
    }
}
