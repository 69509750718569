.tt-DUOFundInfo {
    @import '~/stylesheets/variables/spacing.scss';

    > .tt-List {
        display: flex;

        > .tt-ListItem {
            flex: 1 0;
        }
    }
}
