.tt-DefaultAmountMultiInput {
    @import '../../variables/spacing.scss';
    @import '../../mixins/input.scss';

    display: flex;
    flex-direction: row;
    $spacing: getSpacing(1);
    margin: -($spacing / 2);
    max-width: $input-max-width + $spacing;

    .tt-MultiInput__item {
        display: block;
        float: none;
        width: 50%;
        padding: $spacing / 2;

        &:nth-child(1) {
            width: 30%;
        }

        &:nth-child(2) {
            width: 70%;
        }
    }
}
