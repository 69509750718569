.tt-UserIcon {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    $background-color: $color-red;

    display: block;
    height: getSpacing(3);
    width: getSpacing(3);
    background-color: $background-color;
    border-radius: 2px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba($color-black, 0.2);
    text-decoration: none;

    span {
        color: $color-white;
        line-height: getSpacing(3);
        font-weight: 600;
        font-size: 14px;
    }

    &--is-link {
        &:hover {
            background-color: darken($background-color, 3%);

            // Don't know how to do this other than this:
            .tt-Link__content {
                text-decoration: none;
            }
        }
    }
}
