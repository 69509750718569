.tt-StatisticTableCell {
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/spacing.scss';

    background-color: $color-white;
    color: $color-text;
    height: 116px;

    &__content {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        flex-direction: column;
    }

    &__statistic {
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        margin-bottom: getSpacing(1);
    }

    &__sub-statistic {
        position: relative;
        top: -3px;
        font-size: 14px;
        font-weight: 400;
        color: $color-text-subtle;
        margin-left: getSpacing(0.5);
    }

    &__description {
        font-size: 14px;
        line-height: 1.5;
    }
}
