.tt-CenterModal {
    @import '~/stylesheets/variables/spacing.scss';
    @import '~/stylesheets/variables/colors.scss';
    @import '~/stylesheets/variables/breakpoints.scss';

    &__window {
        display: block;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;

        &__bar {
            padding: getSpacing(2) + 6px getSpacing(1.5);
            border-bottom: 1px solid $color-border;
            height: 85px;

            &__title,
            &__button.tt-Button {
                display: inline-block;
                vertical-align: top;
                padding: 0;

                &:hover {
                    border: none;
                    background: transparent;
                    .tt-Icon {
                        color: #2d373f;
                    }
                }
            }

            &__button.tt-Button {
                float: right;
                border: 0;
                color: $color-grey-subtle;
                height: 41px;
                padding: 0 getSpacing(1);
                margin-right: -1 * getSpacing(1);
                margin-top: -9px;

                &:active {
                    box-shadow: none;
                }
            }
        }
    }

    .tt-ModalFooter {
        border-top: 1px solid $color-border;
    }

    .tt-CenterView {
        &__vertical-center {
            width: 100%;
            height: 100%;
            display: block;
        }

        &__horizontal-center {
            display: block;
            padding-top: 50px;
            padding-bottom: 150px;
        }
    }

    &--is-small-modal {
        .tt-CenterModal__window {
            max-width: 530px;
        }
    }

    &--is-medium-modal {
        .tt-CenterModal__window {
            max-width: 640px;
        }
    }

    @media screen and (max-width: $breakpoint-tablet) {
        .tt-CenterView__horizontal-center {
            width: 100%;
            height: 100%;
            padding: getSpacing(1);
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        &__window {
            width: auto;
            height: auto;

            &__bar {
                padding: (getSpacing(3) - 2px) getSpacing(3);
            }

            &__content {
                .tt-Form {
                    height: auto;
                }
            }
        }

        &.tt-CenterView {
            display: table;
        }

        .tt-CenterView {
            &__vertical-center {
                display: table-cell;
                vertical-align: middle;
            }

            &__horizontal-center {
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }
        }
    }
}
