@import '~/stylesheets/variables/spacing.scss';
@import '~/stylesheets/variables/colors.scss';
@import '~/stylesheets/variables/breakpoints.scss';

.tt-DrawerFooter {
    padding: getSpacing(2) + 6px getSpacing(1.5);
    background-color: #fff;
    width: calc(100% - 80px);
    position: fixed;
    bottom: 0;
    border-top: 1px solid $color-border;

    &::after {
        content: '';
        display: block;
        clear: both;
    }

    .tt-Button {
        margin-left: getSpacing(1);
    }

    @media screen and (min-width: $breakpoint-tablet) {
        height: 85px;
        padding: getSpacing(2) getSpacing(3);
    }
}
